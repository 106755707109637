const Development = () => {
    return (
        <div>
            <div className="page-title-container">
                <h1 className="page-title">Development</h1>
            </div>
            <div className="page-content-container">
                <div className="page-content-title">
                    <h2 className="content-title">Advanced Historical Analysis</h2>
                </div>
                <div className="page-content-list">
                    <ul>
                        <li>
                            <p>
                                This project aims to provide a comprehensive historical analysis of a given topic.
                            It uses advanced algorithms to analyze historical data and provide insights into the topic.
                            </p>
                        </li>
                        <li>
                            <p>
                                ALEXANDRiiiA incorporates natural language processing techniques to extract meaningful information from historical texts and documents. This allows for a deeper understanding of historical contexts and trends.
                            </p>
                        </li>
                        <li>
                            <p>
                                The project features a user-friendly interface that allows researchers and history enthusiasts to easily input queries and receive detailed, data-driven analyses. This makes complex historical data more accessible and interpretable.
                            </p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Development;
