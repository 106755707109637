const Home = () => {
    return (
        <div className='page'>
            <div className='page-title-container'>
                <h1 className='page-title'>we're a little unconventional...</h1>
            </div>
        </div>
    )
}

export default Home;
