//libs
import React from 'react';
//components
import Logo from './Logo';

const Header = () => {
    return (
        <header className='header-container'>
            <Logo />
        </header>
    );
};

export default Header;

