//components
import HamburgerBtn from './buttons/HamburgerBtn';

const Footer = ({isOpen, setIsOpen}) => {
    return (
        <footer className="footer">
            <HamburgerBtn isOpen={isOpen} setIsOpen={setIsOpen}/>
        </footer>
    );
};

export default Footer;