//libs
import React from 'react';
//components
import Link from './Link';
import LogoSVG from './LogoSVG';

const Logo = () => {
    return (
        <Link href="/">
            <LogoSVG/>
        </Link>
    );
};

export default Logo;

