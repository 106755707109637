//components
import Link from './Link';
//icons
import { IoHomeOutline } from 'react-icons/io5';
import { RiLightbulbFlashLine } from 'react-icons/ri';
import { FaConnectdevelop } from 'react-icons/fa';
import { GiSpellBook } from 'react-icons/gi';
import { GrArticle } from 'react-icons/gr';
const Tray = ({isOpen}) => {
    const currentPath = window.location.pathname;
    return (
        <nav className={isOpen? 'tray': 'tray-hidden'} style={{width: '100%'}}>
            <ul style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around', alignItems: 'center', padding: '1rem'}}>
                <li>
                    <Link href="/" className={`nav-item ${currentPath === '/' ? 'active' : ''}`} style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{marginRight: '1rem'}}>Home</span>
                        <IoHomeOutline />
                    </Link>
                </li>
                <li>
                    <Link href='/research' className={`nav-item ${currentPath === '/research' ? 'active' : ''}`} style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{marginRight: '1rem'}}>Research</span>
                        <RiLightbulbFlashLine />
                    </Link>
                </li>
                <li>
                    <Link href='/development' className={`nav-item ${currentPath === '/development' ? 'active' : ''}`} style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{marginRight: '1rem'}}>Development</span>
                        <FaConnectdevelop />
                    </Link>
                </li>
                <li>
                    <Link href='/fiction' className={`nav-item ${currentPath === '/fiction' ? 'active' : ''}`} style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{marginRight: '1rem'}}>Fiction</span>
                        <GiSpellBook />
                    </Link>
                </li>
                <li>
                    <Link href='/essays' className={`nav-item ${currentPath === '/essays' ? 'active' : ''}`} style={{display: 'flex', alignItems: 'center'}}>
                        <span style={{marginRight: '1rem'}}>Essays</span>
                        <GrArticle />
                    </Link>
                </li>
            </ul>
        </nav>
    )
}

export default Tray;