const HamburgerBtn = ({isOpen, setIsOpen}) => {
    return (
        <div className="hamburger-btn" onClick={() => setIsOpen(!isOpen)}>
            <div className={!isOpen ? 'bar': 'bar-1-open'}></div>
            <div className={!isOpen ? 'bar': 'bar-2-hidden'}></div>
            <div className={!isOpen ? 'bar': 'bar-3-open'}></div>
        </div>
    )
}

export default HamburgerBtn;